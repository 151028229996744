@import "../sass-utils/variables";
@import "../sass-utils/mixins";
@import "./checkbox";

.otp-input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 678px) {
    padding: 0;
  }
}

input {
  position: relative;
  &.otp-input {
    width: 13%;
    @include borderRadiusBrowserCompatibility(0);
    padding: 0.5rem;
    text-align: center;
  }
  &:disabled {
    background: #ccc;
  }
}

.input-control {
  .react-datetime-picker {
    position: relative;
    z-index: 10000000;
    width: 100%;
    .react-calendar__navigation {
      background: $primary-color;
      button {
        color: #fff;
        &:enabled:hover,
        &:enabled:focus {
          color: #000;
        }
      }
    }
    .react-datetime-picker__calendar {
      left: unset !important;
      right: 0 !important;
      @media (max-width: 678px) {
        position: relative;
        left: 50% !important;
        @include transformBrowserCompatibility(translateX(-50%) !important);
      }
    }
    .react-datetime-picker__wrapper {
      border: none;
      border-bottom: 1px solid $primary-color;
      padding: 0.5rem 0 0.2rem;
      margin-bottom: 0.5rem;
      height: 2.5rem;
      @media screen and (max-width: 678px) {
        padding: 0.5rem 0 0.5rem 0.5rem;
        font-size: 13px;
      }
      .react-datetime-picker__calendar-button.react-datetime-picker__button {
        position: relative;
        svg {
          display: none;
        }
        &::after {
          background: none;
          display: block;
          font-family: "icomoon";
          content: "\e925";
          position: absolute;
          top: -0.2rem;
          left: -1.5rem;
          color: #000;
          font-weight: 800;
          font-size: 1.1rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          border: 1px solid #000;
          @include borderRadiusBrowserCompatibility(0.5rem);
        }
        &:focus {
          outline: none;
          &::after {
            border: 1px solid $primary-color;
            background: $primary-color;
            color: #fff;
          }
        }
      }
      .react-datetime-picker__clear-button.react-datetime-picker__button {
        display: none;
      }
      input,
      .react-datetime-picker__inputGroup__divider,
      .react-datetime-picker__inputGroup__leadingZero {
        display: none;
      }
    }
  }

  span.dob-value {
    position: absolute;
    left: 0;
    top: 45%;
    @include transformBrowserCompatibility(translateY(-50%));
    padding-right: 2rem;
    @media screen and (max-width: 678px) {
      padding: 0.5rem 1rem;
      padding-left: 0.5rem;
      font-size: 1rem;
    }
  }

  span.icon-eye,
  span.icon-eye-hidden {
    float: right;
    margin-top: -2.5rem;
    position: relative;
    z-index: 2;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.rsd_date-container {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50' /></svg>")
      no-repeat !important;
    background-size: 12px !important;
    background-position: calc(100%) 65% !important;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 100%;
    padding: 0.5rem 1.5rem 0.5rem 0;
    border: 0;
    border-bottom: 1.4px solid $primary-color;
    @include borderRadiusBrowserCompatibility(0);
    margin-bottom: 0.5rem;
    background: transparent;
    @media screen and (max-width: 678px) {
      padding: 0.5rem;
    }
    &:focus,
    &:hover {
      outline: none;
    }
    &.default {
      border-bottom-color: #7a7a7a;
    }
  }
  .rsd_month-container,
  .rsd_day-container {
    margin-right: 0.5rem;
  }
}

.tom-input {
  width: 100%;
  padding: 0.5rem 0;
  border: 0;
  border-bottom: 1.4px solid $primary-color;
  @include borderRadiusBrowserCompatibility(0);
  margin-bottom: 0.5rem;
  background: transparent;
  @media screen and (max-width: 678px) {
    padding: 0.5rem;
  }
  &:focus,
  &:hover {
    outline: none;
  }
  &.default {
    border-bottom-color: #7a7a7a;
  }
}

select {
  &.tom-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50' /></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px) 65%;
    background-repeat: no-repeat;
  }
}

.mobile-no-input {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 5px) 65%;
    background-repeat: no-repeat;
    width: 6rem;
    padding: 0.5rem 0;
    border-right: 0;
    @include borderRadiusBrowserCompatibility(0);
  }
  input {
    width: calc(100% - 6rem);
  }
}

label {
  color: #777;
  margin-bottom: 5px;
}
.input-control {
  position: relative;
  label {
    font-size: 0.9rem;
  }
  p {
    line-height: 1.1;
    margin-bottom: 0.5rem;
  }
  small.error {
    margin-top: -0.5rem;
    text-align: left;
    color: #df0e36;
    display: block;
  }

  &.error {
    input,
    select {
      border-width: 2px;
    }
    small {
      visibility: visible;
      color: $primary-color;
      display: block;
    }
  }
  &.error-checkbox {
    label {
      color: #df0e36;
      small {
        display: inline-block;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 678px) {
  .react-datepicker-wrapper input {
    padding-left: 0.5rem;
  }
  .mobile-no-input {
    select {
      width: 6rem;
      padding: 0.5rem;
    }
    input {
      width: calc(100% - 6rem);
    }
  }
}

button {
  font-family: 'Roboto';
  font-weight: 400;
  padding: 15px 0;
  line-height: 16px;
  &.tom-button {
    @include borderRadiusBrowserCompatibility(2rem);
    border: none;
    text-transform: lowercase;
    width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
    @media (max-width: 678px) {
      width: 100%;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &.primary-button {
    background: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
    &:hover,
    &:focus {
      background: #fff;
      color: $primary-color;
    }
    &:disabled {
      cursor: not-allowed;
      background: rgba($primary-color, 0.5);
    }
  }

  &.default-button {
    background: #7a7a7a;
    border: 1px solid #7a7a7a;
    color: #fff;
    &:hover,
    &:focus {
      background: #fff;
      color: #000;
    }
  }

  &.facebook-button {
    background: $facebook-color;
    color: #fff;
    &:hover,
    &:focus {
      background: rgba($facebook-color, 0.95);
    }
  }

  &.google-button {
    background: #fff;
    color: #000;
    border: 1px solid #aaa !important;
    &:hover,
    &:focus {
      background: #e2e2e2;
    }
  }

  &:focus,
  &:hover {
    outline: none;
  }
}

@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";

.bottom-navigation-component {
  display: none;
  @media screen and (max-width: 678px) {
    display: block;
    position: fixed;
    bottom: 0.1rem;
    height: 5rem;
    width: 99%;
    left: 50%;
    @include transformBrowserCompatibility(translateX(-50%));
    @include borderRadiusBrowserCompatibility(1rem);
    z-index: 100000;
    background: #fff;
    @include borderShadowBrowserCompatibility(0px -1px 4px 1px rgba(0, 0, 0, 0.61));
    .navigation-list {
      display: flex;
      justify-content: space-around;
      position: relative;
      height: 100%;
      text-transform: uppercase;
      .nav-item {
        width: 50%;
        text-align: center;
        .item {
          display: grid;
          align-items: center;
          justify-content: center;
          height: 100%;
          i {
            font-size: 1.2rem;
            color: $primary-color;
          }
        }
        &.login {
          text-align: center;
          position: absolute;
          top: -2.5rem;
          height: 5rem;
          width: 5rem;
          border-radius: 50%;
          background: $primary-color;
          position: absolute;
          bottom: -1.2rem;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          i {
            position: absolute;
            font-size: 3rem;
            top: 50%;
            left: 50%;
            @include transformBrowserCompatibility(translate(-50%, -50%));
            color: #fff;
          }
          p {
            position: relative;
            top: 102%;
            color: #000;
          }
        }
        p {
          @media (max-width: 678px) {
            font-size: 0.7rem;
          }
        }
      }
      .nav-item.active {
        &.login i {
          color: $grey-color;
        }
        p {
          color: $primary-color;
        }
      }
    }
  }
}

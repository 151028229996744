@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";
.service-info-component {
  margin-top: -5rem;
  .container {
    padding: 2rem;
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem);
  }

  @media screen and (max-width: 678px) {
      margin-top: -2rem;
      .container {
          @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
          padding: 2rem 2rem 3rem;
      }
  }
}

@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

section.qr-generate-component {
    padding-bottom: 1rem;
    text-align: center;
    .container {
        padding-top: 1rem;
    }
    .qr-box {
        width: 100%;
        @include borderRadiusBrowserCompatibility(2rem);
        border: 5px solid #999;
        padding: 1rem;
    }

    @media screen and (max-width: 678px) {
        .qr-box {
            width: 100%;
            @include borderRadiusBrowserCompatibility(1rem);
            border: 1.5px solid #999;
            padding: 1rem;
        }
    }
}

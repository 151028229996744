@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";
.footer-component {
  background: $light-grey-color;
  color: $dark-grey-color;
  a {
    color: $dark-grey-color;
  }

  .footer-content {
    position: relative;
    padding: 1rem 0;
    i {
      cursor: pointer;
      font-size: 1.5rem;
      &::before {
        color: $primary-color;
      }
    }
  }

  @media screen and (max-width: 678px) {
    padding-bottom: 3rem;
    .image {
      text-align: center;
      font-size: 7vw;
    }
    .footer-content {
      font-size: 3vw;
      padding: 0.5rem 0;
    }
  }

  .icon-instagram {
      cursor: pointer;
      padding: 2px;
      background-color: #ee1a00;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      margin-left: 7px;
      margin-bottom: 13px;
  }
}

@import "./styles/sass-utils/variables";
@import "./styles/components/input";
@import "./styles/components/arrow";
@import "./styles/fonts";
@import "./styles/components/text-color";
@import "./styles/components/text-size";

body {
  font-family: "Roboto", sans-serif;
  color: $dark-grey-color;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-y;
  font-size: 0.9rem;
  h1,
  h2 {
    font-family: Nunito;
    font-weight: 400;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  h2 {
    font-size: 1.875rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: 60rem;
  padding: 2.5rem 2rem;
}

p,
a,
h2 {
  &:hover {
    text-decoration: none;
  }
  &.title {
    font-size: 1.6rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -3.5px;
      left: 0;
      border-bottom: 3px solid $primary-color;
      @include borderRadiusBrowserCompatibility(5rem);
      width: 7rem;
      max-width: 100px;
    }
  }

  &.reminder {
    position: absolute;
    font-size: 1rem;
    bottom: 0%;
    a {
      color: $primary-color;
    }
  }
  &.forgot-password {
    font-size: 1rem;
  }

  &.read-more {
    font-size: 1rem;
    text-align: right;
  }
}

@media screen and (max-width: 678px) {
  .container {
    padding: 1rem;
  }
}

ul {
  &.dash {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  &.dash > li:before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
  }
}

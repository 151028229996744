@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.about-us-screen section.content-section {
  background: transparent;
  margin-top: -4rem;
  .container {
    max-width: 60rem;
    padding: 2rem;
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem)
  }
  @media screen and (max-width: 678px) {
    margin-top: -3rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
      padding: 2rem 2rem 3rem;
    }
  }
}

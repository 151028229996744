@import "../../../../styles/sass-utils/variables";

.no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    background: #fff;
    width: 100%;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    background: #fff;
    width: 100%;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: $primary-color;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

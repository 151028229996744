@import "../../../styles/sass-utils/mixins";
@import "../../../styles/sass-utils/variables";
@import "../../../styles/layouts/submain-layout";

.support-screen {
  padding-top: 4.9rem;
  section.content-section {
    margin-top: -4rem;
    .container {
      max-width: 60rem;
      padding: 2rem;
      background: #fff;
      @include borderRadiusBrowserCompatibility(3rem);
    }
    .careline {
      font-size: 160%;
    }
    &.email {
      font-size: 140%;
    }
    .icon-phone {
      position: relative;
      font-size: 2rem;
      top: 0.5rem;
    }
    .icon-email {
      position: relative;
      font-size: 2rem;
      top: 0.5rem;
    }
    @media screen and (max-width: 678px) {
      margin-top: -3rem;
      .container {
        @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
        padding: 2rem 2rem 3rem;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding-top: 3rem;
  }
  @include subMainLayout(#fff, "else");
}

@import "../sass-utils/variables";

h1,
h2,
h3,
h4,
p,
b,
a,
i,
span {
    &.text-white {
        color: #fff !important;
    }

    &.text-black {
        color: #000 !important;
    }

    &.text-primary {
        color: $primary-color !important;
    }

    &.text-secondary {
        color: $secondary-color !important;
    }

    &.text-grey {
        color: $grey-color !important;
    }

    &.text-dark-grey {
        color: $dark-grey-color !important;
    }

    &.text-success {
        color: $success-color !important;
    }

    &.text-danger {
        color: $danger-color !important;
    }

    &.text-default {
        color: $default-color !important;
    }

    &.text-warning {
        color: $warning-color !important;
    }

    &.text-facebook {
        color: $facebook-color !important;
    }
}

a {
    &.text-primary {
        &:hover,
        &:focus {
            color: rgba($primary-color, 0.8) !important;
        }
    }
    &.text-white {
        &:hover,
        &:focus {
            color: rgba(255, 255, 255, 0.8) !important;
        }
    }
}

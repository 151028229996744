@import "../../../styles/sass-utils/variables";
@import "../../../styles/components/text-color";
@import "../../../styles/sass-utils/mixins";

nav.navbar-component {
  position: fixed;
  z-index: 10000000;
  width: 100%;
  background: #fff;
  border-bottom: 5px solid $primary-color;
  .container {
    position: relative;
  }
  .brand-logo {
    width: 5rem;
  }
  button.tom-button {
    width: 100%;
    height: 2rem;
    padding: 0;
  }
  .nav-desktop {
    display: block;
    padding: 0.5rem 0;
    position: relative;

    .container {
      max-width: 65rem;
      padding: 0;
    }
    ul.item-center {
      display: flex;
      justify-content: space-around;
      position: absolute;
      width: 60%;
      top: 85%;
      left: 45%;
      padding-bottom: 0.5rem;
      @include transformBrowserCompatibility(translate(-50%, -50%));

      li {
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1.8rem;
        font-weight: 700;
        @media (min-width: 900px) {
          font-size: 0.9rem;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &:hover {
          color: $primary-color;
        }
        &.active {
          color: $primary-color;
          font-weight: 700;
        }
      }
    }

    ul li {
      list-style: none;
      &.dropdown {
        position: relative;
        i.arrow {
          position: absolute;
          top: 15%;
          border: solid $dark-grey-color;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          @include transformBrowserCompatibility(rotate(45deg));
        }
        ol {
          list-style: none;
          position: absolute;
          visibility: hidden;
          max-height: 0;
          padding: 0 1rem;
          white-space: nowrap;
          left: 60%;
          @include transformBrowserCompatibility(translateX(-50%));
          background: #fff;
          top: 2.9rem;
          overflow: hidden;
          @include borderRadiusBrowserCompatibility(0 0 1rem 1rem);
          z-index: 100;
          border-top-width: 1rem;
          border: 2px solid $primary-color;
          text-align: center;
          li {
            font-weight: 400;
            padding: 0;
            color: #000;
            &.active,
            &:hover {
              color: $primary-color;
            }
            &.active {
              font-weight: 700;
            }
          }
        }
        &:hover {
          i.arrow {
            @include transformBrowserCompatibility(rotate(-135deg));
            @include transitionBrowserCompatibility(0.15s transform ease-in);
          }
          ol {
            @include transitionBrowserCompatibility((max-height 0.15s linear, padding 0.15s linear));
            height: auto;
            padding: 0.5rem 1rem 0.75rem;
            visibility: visible;
            max-height: 40rem;
          }
        }
        &.active {
          color: $primary-color;
        }
      }
    }

    ul.right-section {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 12.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
      top: 1.4rem;
      li {
        cursor: pointer;
        &.syiok-oil {
          position: relative;
          padding-right: 0.5rem;
          cursor: auto;
          span.syiok-logo {
            font-family: Nunito;
            font-weight: 700;
            font-size: 1rem;
          }
        }
        &.inbox {
          sup {
            font-size: 70%;
            position: relative;
            z-index: 1;
            color: #fff;
            top: -1.1em;
            right: 0.3rem;
            background: $primary-color;
            @include borderRadiusBrowserCompatibility(50%);
            padding: 0 0.2rem;
          }
        }
        ol {
          top: 3rem;
        }
        i {
          cursor: pointer;
          &.icon-user-copy {
            margin-top: 0.5rem;
            font-size: 1rem;
            color: $primary-color;
            border: 2px solid $primary-color;
            padding: 0.5rem;
            @include borderRadiusBrowserCompatibility(50%);
          }
          &.icon-inbox {
            font-size: 1.4rem;
          }
        }
        &.active {
          color: $primary-color;
          i.icon-user-copy {
            border: 2px solid black;
          }
        }
      }
    }
  }
  .nav-mobile {
    display: none;
    .menu-toggler {
      height: 2rem;
      width: 1.5rem;
      position: relative;
      .menu-bar {
        position: absolute;
        top: 50%;
        @include transformBrowserCompatibility(translateY(calc(-50%)));
        height: 3px;
        width: 100%;
        background: #6d6868;
        @include borderRadiusBrowserCompatibility(3rem);
        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 3px;
          width: 100%;
          background: #6d6868;
          @include borderRadiusBrowserCompatibility(3rem);
        }
        &::after {
          @include transitionBrowserCompatibility(transform 0.25s ease-in-out);
          margin-top: 0.45rem;
        }
        &::before {
          @include transitionBrowserCompatibility(transform 0.25s ease-in-out);
          margin-top: -0.45rem;
        }
      }
      &.active {
        .menu-bar {
          height: 0;
          width: 105%;
          margin-left: -5%;
          &::after {
            margin-top: -0.15rem;
            @include transformBrowserCompatibility(rotate(140deg));
            height: 3px;
          }
          &::before {
            margin-top: -0.15rem;
            @include transformBrowserCompatibility(rotate(-140deg));
            height: 3px;
          }
        }
      }
    }
  }
  .inbox {
    color: #6d6868;
  }
  @media screen and (max-width: 1023px) {
    .nav-desktop {
      display: none;
    }
    .nav-mobile {
      display: flex;
      justify-content: space-between;
      height: 3rem;
      padding: 0.5rem;
      margin-top: auto;
      .brand-logo {
        width: 3rem;
        img {
          width: 100%;
        }
      }
      span.syiok-logo {
        font-family: Nunito;
        font-weight: 700;
        font-size: 1.1rem;
        position: relative;
        top: 0rem;
      }
      .inbox {
        font-size: 1.5rem;
        margin-right: 0.5rem;
        sup {
          color: #fff;
          font-size: 0.9rem;
          top: -1em;
          z-index: 1;
          padding: 0.1rem 0.4rem;
          left: 0.4rem;
          &::before {
            content: "";
            position: absolute;
            background: $primary-color;
            @include borderRadiusBrowserCompatibility(50%);
            height: 100%;
            width: 100%;
            top: 50%;
            left: 50%;
            @include transformBrowserCompatibility(translate(-50%, -50%));
            z-index: -1;
          }
        }
      }
    }
  }
}

@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

section.login-form {
  margin-top: -5rem;
  .container {
    padding: 2.5rem 2rem;
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem);
    .icon-facebook-icon:before {
      color: #fff;
    }
  }
  
  @media screen and (max-width: 678px) {
    margin-top: -2rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
      padding: 2rem 1rem 3rem;
    }
  }
}

@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.survey-form-component {
  margin-top: -5rem;
  .container {
    max-width: 60rem;
    background: #fff;
    padding: 3rem 2rem;
    @include borderRadiusBrowserCompatibility(3rem);
    p {
      &.skip-survey {
        color: $primary-color;
      }
    }
  }

  @media screen and (max-width: 678px) {
    margin-top: -3rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
      padding: 2rem 2rem 3rem;
    }
  }
}

@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";

.side-navigation-component {
    display: block;
    position: fixed;
    right: 5px;
    top: 60%;
    padding: 0 0.3rem;
    background: #fff;
    transform: translateY(-50%);
    @include borderShadowBrowserCompatibility(-3px 6px 10px #cccccc);
    @include borderRadiusBrowserCompatibility(3rem);
    .navigation-list {
        padding: 0.5rem 0.5rem 1rem;
        color: #000;
        text-align: center;
        position: relative;
        @include borderRadiusBrowserCompatibility(3rem);
        p {
            font-size: 0.65rem;
        }
        .item {
            text-transform: uppercase;
            i {
                color: $primary-color;
                &.icon-user-copy {
                    color: #fff;
                }
            }
            &:hover {
                cursor: pointer;
                color: $primary-color;
            }
            &.active {
                color: $primary-color;
            }
        }
        .login {
            position: relative;
            .box {
                position: relative;
                left: -0.5rem;
                width: calc(100% + 1rem);
                background: $primary-color;
                @include borderRadiusBrowserCompatibility(50%);
                padding: 1.25rem 0;
                i {
                    font-size: 2rem;
                    &.icon-sign-up {
                        position: relative;
                        &::after {
                            content: "+";
                            position: absolute;
                            top: 0px;
                            right: 0.5px;
                            font-size: 1.2rem;
                            font-weight: 700;
                        }
                        &::before {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    @media screen and(max-width: 678px) {
        display: none;
    }
}

@import "../../../styles/sass-utils/mixins";
@import "../../../styles/sass-utils/variables";
@import "../../../styles/layouts/submain-layout";

.subscribe-screen {
  padding-top: 10rem;
  span.syiok-logo {
    font-family: Nunito;
    font-weight: 700;
    font-size: 3.3rem;
  }
  @media screen and (max-width: 1023px) {
    padding-top: 3rem;
  }
  @include subMainLayout(#fff, "else");
}

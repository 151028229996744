@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

section.search-form-section {
    margin-top: -3rem;
    .container {
        background: #fff;
        @include borderRadiusBrowserCompatibility(1rem);
        padding: 1rem;
        position: relative;
        z-index: 1;
    }
    .search-group {
        position: relative;
        .search-input {
            @include borderRadiusBrowserCompatibility(0.5rem);
            width: 100%;
            background: #fafafa;
            border: none;
            height: 1.5rem;
            padding: 1.1rem;
        }
        i {
            position: absolute;
            right: 1rem;
            top: calc(50%);
            @include transformBrowserCompatibility(translateY(-50%));
        }
    }
    i {
        cursor: pointer;
        font-size: 1.5rem;
        color: #a6a6a6;
    }
    .autocomplete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }
    .filter-dropdown {
        position: relative;
        ul {
            display: none;
            list-style: none;
            position: absolute;
            right: -1rem;
            width: 14rem;
            background: #fff;
            padding-left: 0;
            border: 1px solid #ccc;
            li {
                border-bottom: 1px solid #000;
                padding: 0.5rem;
                &:nth-last-child(1) {
                    border: 0;
                }
                &:hover {
                    cursor: pointer;
                    background: rgba(219, 219, 219, 0.493);
                }
                &.active {
                    background: $primary-color;
                    color: #fff;
                }
            }
            &.active {
                display: block;
            }
        }
    }
    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }
    /*when hovering an item:*/
    .autocomplete-items div:hover {
        background-color: #e9e9e9;
    }
    /*when navigating through the items using the arrow keys:*/
    .autocomplete-active {
        background-color: DodgerBlue !important;
        color: #ffffff;
    }
    @media screen and (max-width: 678px) {
        margin-top: -3rem;
        i {
            font-size: 1rem;
        }
    }
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.step-bar-component {
    position: relative;
    width: 100%;
    .line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 0.25rem;
        width: 100%;
        background: #ababab;
    }
    .dots {
        position: relative;
        .dot {
            width: 1.5rem;
            height: 1.5rem;
            background: #ababab;
            @include borderRadiusBrowserCompatibility(50%);
            &.active {
                background: $primary-color;
            }
        }
    }

    @media screen and (max-width: 678px) {
        .line {
            height: 0.15rem;
        }
        .dots .dot {
            width: 1rem;
            height: 1rem;
        }
    }
}

@import "../../../styles/sass-utils/variables";

section.welcome-component {
  background: $light-grey-color;
  color: $primary-color;
  text-align: center;
  padding: 4rem 0 7rem;
  @media screen and (max-width: 678px) {
    padding: 2rem 0 3rem;
    h2 {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}

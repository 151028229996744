@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.loyalty-screen section.service-notification-component {
  background: transparent;
  margin-top: -4rem;
  .container {
    background: #000;
    padding: 2rem 0 6rem;
    @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
  }
  @media screen and (max-width: 678px) {
    background: #000;
    margin-top: -1rem;
    padding-bottom: 1rem;
    @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
    @include borderShadowBrowserCompatibility(0px 0px 5px 1px rgba(0, 0, 0, 0.59));
    .container {
      padding: 1rem 15px;
    }
  }
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.product {
  .product-description {
    font-size: 1rem;
    display: flex;
    justify-content: space-evenly;
    .value p {
      font-weight: 500;
    }
    img {
      width: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .product {
    .product-description {
      font-size: 3vw;
      display: flex;
      justify-content: space-evenly;
      .value p {
        font-weight: 500;
      }
      img {
        width: 3.5vw;
      }
    }
  }
}

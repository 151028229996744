@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

section.step-three-form-component {
	margin-top: -4rem;
	.container {
		padding: 4rem 2rem 0;
		background: #fff;
		@include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
	}
	.upload-img {
		position: relative;
		cursor: pointer;
		width: 100%;
		height: 20rem;
		background: transparent;
		border: 2px solid #ccc;
		@include borderRadiusBrowserCompatibility(1rem);
		&:hover {
			background: rgb(255, 221, 221);
		}
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 10rem;
			@include transformBrowserCompatibility(translate(-50%, -50%));
			#output-img {
				height: 100%;
				width: auto;
			}
		}
	}
	.file-extension {
		position: absolute;
		z-index: 1;
		bottom: 0rem;
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
	}

	@media screen and (max-width: 678px) {
		margin-top: -3rem;
		.container {
			padding-top: 2rem;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 3rem;
			@include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
		}
	}
}

@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

.product-choose-component {
  margin-top: -4rem;
  background: transparent;
  .step-section {
    margin-bottom: 2rem;
    .container {
      padding: 4rem 2rem 0;
      background: #fff;
      @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
    }
  }
  .tab-bar {
    border-bottom: 1px solid $primary-color;
    .tab {
      color: #fff;
      background: #ababab;
      cursor: pointer;
      &:first-child {
        @include borderRadiusBrowserCompatibility(25% 75% 0% 100% / 100% 0% 100% 0%);
      }
      &:nth-child(2) {
        @include borderRadiusBrowserCompatibility(75% 25% 100% 0% / 0% 100% 0% 100%);
      }
      &.active {
        background: $primary-color;
        top: -0.05rem;
        @include transformBrowserCompatibility(scaleY(1.05));
      }
    }
  }
  .option-box {
    cursor: pointer;
    text-align: center;
    &.active {
      background-color: $primary-color;
      color: #fff;
    }
  }

  @media screen and (max-width: 678px) {
    margin-top: -3rem;
    .step-section {
      padding-top: 1rem;
      margin-bottom: 0;
      .container {
        @include borderRadiusBrowserCompatibility(1rem);
        padding: 1rem;
      }
    }
    .tab-bar {
      border-bottom: 1px solid $primary-color;
      .tab {
        &:first-child {
          @include borderRadiusBrowserCompatibility(1rem 0 0 0);
        }
        &:nth-child(2) {
          @include borderRadiusBrowserCompatibility(0 1rem 0 0);
        }
        &.active {
          @include transformBrowserCompatibility(scaleY(1.05));
        }
      }
    }
    .product-selection {
      text-align: center;
      font-size: 3vw;
    }
  }
}

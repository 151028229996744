@import "../../../styles/sass-utils/mixins";
@import "../../../styles/sass-utils/variables";

.top-section-component {
  font-size: 1.5rem;
  background: $light-grey-color;
  text-align: center;
  padding: 3rem 0 5rem;
  h1,
  h2 {
    font-family: Nunito;
    font-weight: 400;
    color: $primary-color;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  h1 {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 678px) {
    padding: 2rem 0 4rem;
  }
}

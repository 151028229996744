@import "../../../styles/sass-utils/variables";
@import "../../../styles/layouts/submain-layout";

.survey-screen {
    padding-top: 4.9rem;
    @media (max-width: 1023px) {
        padding-top: 3rem;
    }
    @include subMainLayout(#fff, 'else');
}

@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";

.home-screen {
    padding-top: 4.9rem;

    .container {
        padding: 0;
    }
    h2 {
        font-family: Nunito;
        font-weight: 400;
        color: $dark-grey-color;
        font-size: 1.875rem;
        text-transform: lowercase;
        &::first-letter {
            text-transform: uppercase;
        }
    }
    p {
        font-family: 'Roboto';
        font-weight: 400;
        color: $dark-grey-color;
    }
    button {
        &.arrow {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 48%;
                right: 10px;
                font-weight: 700;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3.5px;
                @include transformBrowserCompatibility(rotate(-45deg) translateY(-50%));
            }
            &:hover::after {
                content: "";
                border: solid $primary-color;
                border-width: 0 2px 2px 0;
            }
            @media (max-width: 400px) {
                padding-right: 1rem;
                font-size: 0.7rem;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        padding-top: 3rem;
        .container {
            padding: 0 1rem;
        }
        button.arrow::after {
            right: 5px;
            top: 43%;
        }
    }
}

@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

section.bottle-quantity-component {
  background: $secondary-color;
  @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);

  @media screen and (max-width: 678px) {
    .input-control {
      margin-top: auto;
      margin-bottom: auto;
      .tom-checkbox {
        padding-left: 1.5rem;
        margin-bottom: 0;
      }
    }
  }
}

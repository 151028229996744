@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";
@import "../../../styles/layouts/submain-layout";

.page-not-found-screen {
    padding-top: 4.9rem;
    @media screen and (max-width: 678px) {
      .content-section {
        .container {
          @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
          margin-top: -3rem;
          padding: 2rem 1rem 3rem;
        }
      }
    }
    @media (max-width: 1023px) {
        padding-top: 3rem;
    }
    @include subMainLayout(#fff, "100");
}
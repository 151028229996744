$primary-color: #ed0000;
$secondary-color: #f9f9f9;
$light-grey-color: #F7F9FA;
$grey-color: #b7cbd3;
$dark-grey-color: #374649;
$success-color: green;
$danger-color: red;
$warning-color: orange;
$default-color: blue;
$facebook-color: #3c5898;

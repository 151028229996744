@import "../../../styles/sass-utils/variables";
@import "../../../styles/layouts/submain-layout";

.member-get-member-screen {
    padding-top: 3rem;
    @media screen and (max-width: 1023px) {
        padding-top: 3rem;
    }
    @include subMainLayout(#fff, 'else');
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.loyalty-screen section.registered-vehicle-component {
  .container {
    padding: 1rem 0;
    .item {
      position: relative;
      padding-bottom: 3rem;
      @media (max-width: 678px) {
        padding-bottom: 4rem;
      }

      &.active-campaign {
        padding-bottom: 7rem;
      }

      .service-box {
        cursor: pointer;
        padding: 1.2rem 1.5rem 0;
        @include borderRadiusBrowserCompatibility(2rem);
        @include borderShadowBrowserCompatibility(
          0px 0px 6px 0px rgba(0, 0, 0, 0.61)
        );
        @include userSelectCompatibility();
        .service-info-section {
          background: $secondary-color;
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          padding: 0.5rem 1.5rem;
        }
        .car-info-section {
          padding-bottom: 0.5rem;
          .car-info {
            display: flex;
            justify-content: space-between;
            div {
              &:first-child {
                width: 60%;
              }
              &:last-child {
                width: 30%;
                margin-top: auto;
                margin-bottom: auto;
              }
            }
          }
        }
      }
      .service-description-button {
        position: absolute;
        width: 100%;
        left: 1rem;
        padding: 0.5rem;
      }
    }
  }

  .block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 2rem;
    margin: 0 auto;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-multi-carousel-list {
    overflow-x: scroll;
    padding-bottom: 1rem;
    @media (max-width: 678px) {
      overflow-x: hidden;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 678px) {
    .container {
      padding: 1rem 15px 3rem;
      .item .service-box {
        padding: 0.2rem 0.5rem 0;
        @include borderRadiusBrowserCompatibility(0.5rem);
        @include borderShadowBrowserCompatibility(
          0px 0px 6px 0px rgba(0, 0, 0, 0.61)
        );
        .service-info-section {
          margin: 0 -0.5rem;
        }
        .car-info-section {
          padding: 0.5rem 0;
          .car-info {
            font-size: 15px;
          }
          .car-icon {
            width: 30%;
          }
        }
        .service-info-section {
          padding: 0.5rem;
          p {
            font-size: 15px;
          }
        }

        .service-status-section {
          // height: 4rem;
          div {
            padding: 0 0.25rem;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

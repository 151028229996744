@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.loyalty-screen section.header-section-component {
  background: $light-grey-color;
  .container {
    padding: 2rem 0 6rem;
  }

  @media screen and (max-width: 678px) {
    .container {
      padding: 1rem 15px 2rem;
    }
  }
}

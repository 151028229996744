@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

section.top-banner-section {
  position: relative;
  .user-info-section {
    background: $light-grey-color;
    padding: 0.5rem 0 0.2rem;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    p {
      margin-bottom: 0;
      color: $primary-color;
    }
    .link {
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
    @media (max-width: 374px) {
      p, a {
        font-size: .6rem;
      }
    }
    @media (min-width: 375px) and (max-width: 400px) {
      p, a {
        font-size: 0.65rem;
      }
    }
    @media (min-width: 401px) and (max-width: 420px) {
      p, a {
        font-size: 0.7rem;
      }
    }
  }
  .react-multi-carousel-dot-list {
    position: relative;
    top: 7.5rem;
  }
  .react-multi-carousel-dot--active button {
    background: $primary-color !important;
    width: 3rem !important;
  }
  .react-multi-carousel-dot button {
    display: inline-block;
    background: $grey-color;
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 4rem;
    border-width: 0px;
  }
  .card {
    position: relative;
    margin-bottom: 3rem;
    background: $light-grey-color;
    font-family: Nunito;
    @include borderRadiusBrowserCompatibility(0 1rem 1rem 1rem);
    @include borderShadowBrowserCompatibility(0px 3px 8px $grey-color);
    border: 1px solid $light-grey-color;
    h2 {
      color: $primary-color;
    }
    p {
      font-family: 'Roboto';
      font-weight: 400;
      color: $dark-grey-color;
      @media (min-width: 800px) {
        font-size: 1.2rem;
      }
    }
  }
  .react-multi-carousel-list {
    top: 2rem;
  }

  @media screen and (max-width: 678px) {
    .react-multi-carousel-dot button {
      height: 4px;
      width: 0.7rem;
    }
    .react-multi-carousel-dot-list {
      top: 9rem;
    }
    .card {
      top: 0.5rem;
      margin-bottom: 3rem;
      .col-button {
        margin-left: -1rem;
      }
      button.tom-button {
        width: calc(100% + 1rem);
      }
    }
  }
}

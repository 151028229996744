@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.member-get-member-success-screen section.content-section {
    margin-top: -5rem;
    .card {
        &.card-main {
            background: #fff;
            padding: 2rem 2rem;
            @include borderRadiusBrowserCompatibility(1.5rem);
            p {
                margin-bottom: 0;
                b {
                    font-size: 1.5rem;
                    color: $primary-color;
                }
                span {

                }
            }
            .product-brand {
                position: relative;
                img {
                    position: absolute;
                    top: -3.5rem;
                    left: 50%;
                    height: 11rem;
                    width: auto;
                    @include transformBrowserCompatibility(translateX(-50%));
                    @media screen and (max-width: 678px) {
                        top: -3.5rem;
                        max-height: 8.5rem;
                        left: 1.5rem;
                    }
                }
            }
            &:hover {
                cursor: pointer;
                background: #d8d8d8;
            }
        }
    }
    @media screen and (max-width: 678px) {
        margin-top: -3rem;
        @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
        .container {
            padding: 2rem 1rem 3rem;
            .card.card-main {
                padding: 2.5rem 2rem;
                p b {
                    font-size: 18px;
                }
            }
        }
    }
}

@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

section.maps-section {
    margin-top: -3rem;
    position: relative;
    z-index: 0;
    .container {
        padding: 0 1rem;
    }
    .leaflet-container {
        width: 100%;
        height: calc(100vh - 22rem);
        @media screen and (max-width: 678px) {
            height: calc(100vh - 15rem);
        }
        .leaflet-top {
            top: 15px;
        }
        .leaflet-bottom.leaflet-right {
            display: none;
        }
        .leaflet-popup.leaflet-zoom-animated {
            bottom: 1rem !important;
        }
        .leaflet-popup-content {
            top: -2rem;
            min-width: 20rem;
            p {
                padding: 7px 0 10px;
            }
            button.tom-button {
                padding: 0.5rem 0;
            }
        }
    }
    @media screen and (max-width: 678px) {
        .container {
            padding: 0 1rem;
        }
    }
}
a.leaflet-popup-close-button {
    border: 2px solid!important;
    height: 24px!important;
    width: 24px!important;
    padding: 0!important;
    text-align: center!important;
    border-radius: 2rem!important;
    padding: 2px 0 0 0!important;
    right: 0.2rem!important;
    top: 0.2rem!important;
}
.leaflet-popup-content p {
    padding: 0;
}

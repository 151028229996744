@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

section.add-vehicle-form {
  margin-top: -4rem;
  .container {
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
  }
  @media screen and (max-width: 678px) {
    margin-top: -3rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem);
    }
  }
}

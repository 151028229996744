@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.our-products-component {
  background: $light-grey-color;
  .content {
    position: relative;
    padding: 2rem 1rem;
  }
  .tab {
    @include borderRadiusBrowserCompatibility(2rem);
    overflow: hidden;
    @include borderShadowBrowserCompatibility(0px 3px 8px $grey-color);
    .tab-header {
      cursor: pointer;
      text-align: center;
      background: $primary-color;
      p {
        color: #fff;
      }
      user-select: none;
      .tab-header-item {
        padding-bottom: 2rem;
        p {
          font-family: Nunito;
          font-weight: 700;
        }
        &:hover {
          opacity: 0.9;
          p {
            color: #fff;
          }
          &.active {
            opacity: 1;
            cursor: not-allowed;
          }
        }
        &.active {
          background: #fff;
          p {
            color: $primary-color;
          }
        }
        &:nth-child(1) {
          &.active {
            @include borderRadiusBrowserCompatibility(0 1rem 0 0);
          }
        }
        &:nth-child(2) {
          &.active {
            @include borderRadiusBrowserCompatibility(1rem 0 0 0);
          }
        }
        text-transform: lowercase;
        &::first-letter {
          text-transform: capitalize;
        }
        @media (min-width: 500px) {
          font-size: 1.4rem;
        }
      }
    }
    .tab-body {
      position: relative;
      margin-top: -2rem;
      background: #fff;
      color: #000;
      @include borderRadiusBrowserCompatibility(1rem);
      overflow: hidden;
      height: 0;
      visibility: hidden;
      &.tab-body-2 {
        margin-top: -1.5rem;
      }
      &.active {
        height: 100%;
        visibility: visible;
      }
      .detail-point {
        padding-top: 3rem;
        .refer-button {
          position: relative;
          button {
            position: absolute;
            top: -4.5rem;
          }
        }
        img {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  @media screen and (max-width: 678px) {
    .content {
      padding: 2rem 0 2rem;
      .tab-header {
        font-size: 4vw;
      }
    }
  }
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

section.registration-form {
  margin-top: -5rem;
  .container {
    padding: 2.5rem 2rem;
    @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
    background: #fff;
  }
  @media screen and (max-width: 678px) {
    margin-top: -2rem;
    .container {
      padding: 2rem 1rem;
      width: 100%;
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
    }
  }
}

@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

.step-one-form-component {
  margin-top: -4rem;
  background: transparent;
  .container {
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
    padding: 4rem 2rem;
  }
  @media screen and (max-width: 678px) {
    margin-top: -3rem;
    padding-top: 1rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
      padding: 1rem;
    }
  }
}

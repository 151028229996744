@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.home-screen section.refer-friend {
    background: #fff;
    .card {
        @include borderRadiusBrowserCompatibility(1.5rem);
        @include borderShadowBrowserCompatibility(0px 3px 4px #e8eaeb);
        overflow: hidden;
        border: none;
    }
}

@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.inbox-screen section.inbox-list {
    color: $dark-grey-color;
    margin-top: -4rem;
    .container {
        max-width: 60rem;
        padding: 3rem 2rem;
        background: #fff;
        @include borderRadiusBrowserCompatibility(3rem);
    }
    .card {
        @include borderRadiusBrowserCompatibility(0.8rem);
        padding: 0.5rem 1rem;
        cursor: pointer;
        border: none;
        @include borderShadowBrowserCompatibility(1px 1px 5px #000);
        position: relative;
        &:hover {
            background: #eeeeee;
            color: #535353;
            .arrow-bottom {
                border-color: #747373 transparent transparent transparent;
                @include transformBrowserCompatibility(translateY(0.1rem));
            }
        }
        &.active {
            background: #eeeeee;
            color: #000;
            p {
                white-space: normal;
            }
            .arrow-bottom {
                border-color: #747373 transparent transparent transparent;
                @include transformBrowserCompatibility(rotateX(180deg));
            }
        }
        .inbox-title,
        .date {
            font-weight: 700;
        }
        .status-indicator {
            height: 0.9rem;
            width: 0.9rem;
            background: $dark-grey-color;
            @include borderRadiusBrowserCompatibility(50%);
            margin-top: 0.2rem;
            &.unread {
                background: green;
            }
        }
        .arrow-bottom {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.8rem 0.5rem 0 0.5rem;
            border-color: #8b8b8b transparent transparent transparent;
            @media (max-width: 678px) {
                border-width: 0.5rem 0.3rem 0 0.3rem;
            }
            @include transitionBrowserCompatibility(transform 0.25s ease-in-out);
        }
        p {
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .card-spinner {
            position: absolute;
            min-height: 5rem;
            height: 100%;
            width: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            display: none;
            cursor: not-allowed;
            .spinner-border {
                position: absolute;
                top: 25%;
                left: calc(50% - 1rem);
                width: 2rem;
                height: 2rem;
                // @include transformBrowserCompatibility(translate(-50%, -50%));
            }
            &.loading {
                display: block;
            }
        }
    }

    @media screen and (max-width: 678px) {
        margin-top: -3rem;
        .container {
            @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
            padding: 2rem 2rem 3rem;
        }
    }
}

@import "../../../../../../styles/sass-utils/variables";
@import "../../../../../../styles/sass-utils/mixins";

.service-description-component {
	margin-top: -4rem;
	.container {
		padding: 4rem 2rem 0;
		background: #fff;
		@include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
		.service-description {
			p {
				&:first-child {
					width: 40%;
				}
				&:nth-child(2) {
					width: 60%;
				}
			}
		}
	}

	@media screen and (max-width: 678px) {
		margin-top: -3rem;
		.container {
			padding-top: 2rem;
			@include borderRadiusBrowserCompatibility(1rem);
			p {
				font-size: 14px;
			}
			.service-description {
				p {
					font-size: 14px;
				}
			}
		}
	}
}

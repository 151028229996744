@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

.sidebar-item-component {
  .sidebar-item-header {
    background: #ececec;
    color: $primary-color;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem 1.6rem;
    span.syiok-logo {
      font-family: Nunito;
      font-weight: 700;
      font-size: 1.1rem;
      position: relative;
      top: -0.1rem;
    }
    .close-sidebar {
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      cursor: pointer;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: -1.5px;
        height: 3px;
        width: 100%;
        background: $primary-color;
        @include transformBrowserCompatibility(rotate(45deg) translateY(-50%));
        @include borderRadiusBrowserCompatibility(2rem);
      }
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 1px;
        height: 3px;
        width: 100%;
        background: $primary-color;
        @include transformBrowserCompatibility(rotate(-45deg) translateY(-50%));
        @include borderRadiusBrowserCompatibility(2rem);
      }
    }
    img {
      max-width: 4rem;
    }
  }
  ul {
    position: absolute;
    bottom: -4rem;
    height: 100%;
    width: 100%;
    padding: 2rem 1rem 0;
    list-style: none;
    font-size: 13px;
    @include borderRadiusBrowserCompatibility(1rem);
    background: #fff;
    overflow-y: auto;
    text-transform: uppercase;
    li {
      display: relative;
      border-bottom: 1px solid #ececec;
      padding: 0.5rem 0 0.5rem 0.5rem;
      width: 100%;
      color: $dark-grey-color;
      &.active {
        font-weight: 700;
        color: $primary-color;
      }
      i {
        font-size: 1rem;
        padding-right: 1rem;
        color: $primary-color;
      }
      &.parent {
        position: relative;
        #products-list-item {
          width: calc(100% + 1rem);
        }
        span.arrow-dropdown {
          right: 0;
        }
        ol {
          display: none;
          position: relative;
          list-style: none;
          padding-left: 0;
          padding-right: 0;
          margin-left: -1rem;
          margin-right: -1rem;
          color: #606060;
          font-weight: 400;
          li {
            padding: 0.2rem 0rem 0.2rem 3rem;
            border-color: #ececec;
            &.active {
              border-color: $primary-color;
            }
          }
        }
        &.active {
          border-bottom: 0;
          span.arrow-dropdown {
            transform: rotate(180deg);
          }
          #products-list-item {
            padding-bottom: 0.4rem;
          }
          ol {
            display: block;
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            border-bottom: 1px solid;
            top: 2.1rem;
            left: 0;
          }
        }
      }
      span {
        &.arrow-dropdown {
          position: absolute;
          right: 1rem;
          margin-top: 0.6rem;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
        }
      }
    }
  }
}

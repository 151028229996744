@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";

.sidebar-component {
  display: flex;
  position: fixed;
  height: 100%;
  bottom: 0;
  width: 100vw;
  z-index: 10000001;
  left: 100%;
  .close-section {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #676666bd;
    z-index: 10001;
  }
  .item {
    position: absolute;
    left: 100%;
    height: 100%;
    width: 80%;
    background: #fff;
    z-index: 10002;
    @include transitionBrowserCompatibility(left 0.25s ease-in-out);
  }
  &.active {
    left: 0;
    .item {
      left: 20%;
    }
  }
}

@import "../../../../styles/sass-utils/variables";
@import "../../../../styles/sass-utils/mixins";

section.cny-campaign-form {
    margin-top: -4rem;
    background: transparent;
    .container {
        max-width: 60rem;
        background: #fff;
        padding: 2.5rem 2rem;
        @include borderRadiusBrowserCompatibility(3rem);
    }
    .user-number {
        span.mobile-no {
            position: relative;
            margin: 1.5rem 0;
            @media screen and (max-width: 678px) {
                padding: 0 0.5rem;
            }
            i {
                cursor: pointer;
                color: $primary-color;
                &:hover {
                    background: $primary-color;
                    color: #fff;
                    @include borderRadiusBrowserCompatibility(50%);
                }
            }
            .tooltip-tom {
                display: none;
                position: absolute;
                top: 50%;
                width: calc(100% + 4rem);
                left: calc(100% + 1rem);
                transform: translateY(-50%);
                background: #fff;
                padding: 0.5rem;
                z-index: 1000000;
                @include borderShadowBrowserCompatibility(2px 6px 21px -5px rgba(0, 0, 0, 0.75));
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -15px;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #fff transparent transparent;
                }
                &.active {
                    display: block;
                }
            }
        }
    }

    @media screen and (max-width: 678px) {
        margin-top: -3rem;
        .container {
            @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
            padding: 2rem 2rem 3rem;
        }
        .user-number span.mobile-no {
            .tooltip-tom {
                min-width: 10rem;
                left: calc(100% + 0.5rem);
            }
        }
    }
}
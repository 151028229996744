@import "../sass-utils/variables";

@mixin subMainLayout($background_color, $template) {
    @media screen and (max-width: 678px) {
        @if $template == 'login' {
            min-height: calc(100vh - 3rem);
        }
        @if $template == '100' {
            min-height: calc(100vh);
        }
        @if  $template == 'else' {
            min-height: calc(100vh - 4.5rem);
        }
        background-color: $background_color;
    }
}

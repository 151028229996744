@import "../../../../styles/sass-utils/mixins";

.swiper {
    @include transformBrowserCompatibility(scale(1.5));
}

.swiper-container {
    width: 100%;
    height: 100%;
    padding-bottom: 3rem !important;
    user-select: none;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    // background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-prev {
    background: url(/assets/images/home/previous-arrow.png);
    background-size: 100% 100%;
    width: 2rem;
    height: 1.5rem;
    left: 30%;
    position: absolute;
    bottom: 1.8rem;
    z-index: 1000;
    @media screen and (max-width: 678px) {
        bottom: 1rem;
    }
    &::after {
        content: "Previous";
        position: relative;
        top: 1rem;
        font-size: 0.6rem;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 370px) {
        width: 1.6rem;
        height: 1.1rem;
        bottom: 1.8rem;
        left: 3.5rem;
    }
}
.swiper-button-next {
    background: url(/assets/images/home/next-arrow.png);
    background-size: 100% 100%;
    width: 2rem;
    height: 1.5rem;
    right: 30%;
    position: absolute;
    bottom: 1.8rem;
    z-index: 1000;
    @media (min-width: 371px) and (max-width: 678px) {
        bottom: 1rem;
    }
    &::after {
        content: "Next";
        position: relative;
        top: 1rem;
        font-size: 0.6rem;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 370px) {
        width: 1.6rem;
        height: 1.1rem;
        bottom: 1.8rem;
        right: 3.5rem;
    }
}

.swiper-slide {
    display: flex;
    flex-direction: column;
}
.swiper-slide p {
    display: none;
    font-size: 0.6rem;
    position: absolute;
    top: 100%;
    width: 200%;
    font-weight: 700;
    @media (max-width: 370px) {
        top: 106%;
        font-size: 0.55rem;
    }
}

.swiper-slide.swiper-slide-active p {
    display: block;
}

@media (min-width: 371px) and (max-width: 678px) {
    .swiper-container {
        padding-bottom: 2rem !important;
    }
    .swiper-button-prev {
        bottom: 0.7rem;
        left: 20%;
    }
    .swiper-button-next {
        bottom: 0.7rem;
        right: 20%;
    }
}

@import "../../styles/sass-utils/variables";
@import "../../styles/sass-utils/mixins";

.popup-component {
  background: #5c5c5cb9;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000001;
  text-align: center;
  visibility: hidden;
  .popup-verification-status {
    position: absolute;
    background: #fff;
    left: 50%;
    @include transformBrowserCompatibility(translateX(-50%));
    width: 0;
    max-width: 25rem;
    top: 30%;
    @include borderRadiusBrowserCompatibility(1.1rem);
    box-shadow: 0px -6px 0 $primary-color;
    max-height: 0;
    overflow: hidden;
    padding: 1.5rem 0.5rem 0.5rem;
    @include transitionBrowserCompatibility((width 0.15s ease-in-out, max-height 0.1s ease-in));
  }
  &.active {
    visibility: visible;
    .popup-verification-status {
      height: auto;
      width: 50%;
      min-width: 17rem;
      max-height: 40rem;
    }
  }
  @media screen and (max-width: 678px) {
    .popup-verification-status {
      font-size: 3vw;
      padding: 1.5rem 0.5rem 0.5rem;
      width: 70%;
    }
  }
}

@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";
@import "../../../styles/layouts/submain-layout";

.privacy-policy-screen {
  padding-top: 4.9rem;
  @media screen and (max-width: 1023px) {
    padding-top: 3rem;
  }
  @include subMainLayout(#fff, "else");
  dl,
  ol,
  ul {
    margin-left: -1.5rem;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.3rem;
    }
  }
}

@import "../../styles/sass-utils/variables";
@import "../../styles/sass-utils/mixins";

.progress-bars {
    position: relative;
    height: 20px;
    width: 100%;
    border: 1px solid $primary-color;
    @include borderRadiusBrowserCompatibility(50px);
    span {
        position: absolute;
        color: #7d7d7d;
        left: 50%;
        top: 50%;
        @include transformBrowserCompatibility(translate(-50%, -50%));
    }
}

.filler {
    background: $primary-color;
    height: 100%;
    @include borderRadiusBrowserCompatibility(inherit);
    @include transitionBrowserCompatibility(width 0.2s ease-in);
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.send-otp-register-component {
  margin-top: -5rem;
  .container {
    padding: 2.5rem 2rem;
    @include borderRadiusBrowserCompatibility(3rem 3rem 0 0);
    background: #fff;
    span {
      color: $primary-color;
    }
    #expired-content {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
      &:hover {
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 678px) {
    margin-top: -2rem;
    .container {
      padding: 2rem 1rem 0;
      height: 100%;
      width: 100%;
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
    }
  }
}

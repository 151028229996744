@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedBold.eot");
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedBold.woff2") format("woff2"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedBold.woff") format("woff"),
    url("/assets/fonts//HelveticaNeue-CondensedHelveticaNeueCondensed-CondensedBold.ttf") format("truetype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedBold.svg#HelveticaNeueCondensed-CondensedBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.eot");
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.woff2") format("woff2"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.woff") format("woff"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.ttf") format("truetype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueThinCond-CondensedRegularThin.svg#HelveticaNeueThinCond-CondensedRegularThin")
      format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.eot");
  src: url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.woff2") format("woff2"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.woff") format("woff"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.ttf") format("truetype"),
    url("/assets/fonts/HelveticaNeue-Condensed/HelveticaNeueCondensed-CondensedRegular.svg#HelveticaNeueCondensed-CondensedRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icon-pack/icon-pack.eot?xhdf2v");
  src: url("/assets/fonts/icon-pack/icon-pack.eot?xhdf2v#iefix") format("embedded-opentype"),
    url("/assets/fonts/icon-pack/icon-pack.ttf?xhdf2v") format("truetype"),
    url("/assets/fonts/icon-pack/icon-pack.woff?xhdf2v") format("woff"),
    url("/assets/fonts/icon-pack/icon-pack.svg?xhdf2v#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-sign-up:before {
  content: "\e902";
  color: #fff;
}
.icon-checked:before {
  content: "\e903";
}
.icon-copy:before {
  content: "\e904";
}
.icon-file:before {
  content: "\e905";
}
.icon-inbox:before {
  content: "\e906";
}
.icon-share-copy:before {
  content: "\e907";
}
.icon-warning:before {
  content: "\e908";
}
.icon-burger:before {
  content: "\e909";
}
.icon-car:before {
  content: "\e90a";
}
.icon-checked1:before {
  content: "\e90b";
}
.icon-delete:before {
  content: "\e90c";
}
.icon-drop:before {
  content: "\e90d";
}
.icon-facebook-icon:before {
  content: "\e90e";
}
.icon-form:before {
  content: "\e90f";
}
.icon-home-run:before {
  content: "\e910";
}
.icon-information:before {
  content: "\e911";
}
.icon-logout:before {
  content: "\e912";
}
.icon-megaphone:before {
  content: "\e913";
}
.icon-phone-call:before {
  content: "\e914";
}
.icon-pin:before {
  content: "\e915";
}
.icon-placeholder:before {
  content: "\e916";
}
.icon-qr-code-scan:before {
  content: "\e917";
}
.icon-refer:before {
  content: "\e918";
}
.icon-uniE916:before {
  content: "\e919";
}
.icon-eye-hidden:before {
  content: "\e91a";
}
.icon-eye:before {
  content: "\e91b";
}
.icon-time:before {
  content: "\e91c";
}
.icon-user-copy:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e91e";
}
.icon-warning1:before {
  content: "\e91f";
}
.icon-water:before {
  content: "\e920";
}
.icon-www1:before {
  content: "\e921";
}
.icon-zoom:before {
  content: "\e922";
}
.icon-badge:before {
  content: "\e923";
}
.icon-bottle:before {
  content: "\e924";
}
.icon-calendar:before {
  content: "\e925";
}
.icon-cancel:before {
  content: "\e926";
}
.icon-check-mark:before {
  content: "\e927";
}
.icon-delete1:before {
  content: "\e928";
}
.icon-file1:before {
  content: "\e929";
}
.icon-filter:before {
  content: "\e92a";
}
.icon-hide:before {
  content: "\e92b";
}
.icon-inbox1:before {
  content: "\e92c";
}
.icon-loupe:before {
  content: "\e92d";
}
.icon-pencil:before {
  content: "\e92e";
}
.icon-share-copy1:before {
  content: "\e92f";
}
.icon-www:before {
  content: "\e930";
}
.icon-target:before {
  content: "\e931";
}
.icon-question:before {
  content: "\e932";
}
.icon-uniE931:before {
  content: "\e933";
}
.icon-uniE932:before {
  content: "\e934";
}
.icon-facebook-circular-logo:before {
  content: "\e935";
}

@font-face {
  font-family: 'iconwarn';
  src:  url('/assets/fonts/icon-warning/icon-warning.eot?bvr96a');
  src:  url('/assets/fonts/icon-warning/icon-warning.eot?bvr96a#iefix') format('embedded-opentype'),
    url('/assets/fonts/icon-warning/icon-warning.ttf?bvr96a') format('truetype'),
    url('/assets/fonts/icon-warning/icon-warning.woff?bvr96a') format('woff'),
    url('/assets/fonts/icon-warning/icon-warning.svg?bvr96a#iconwarn') format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icons-"], [class*=" icons-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconwarn' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-warning {
  &:before {
    content: "\e900";     
    color: #df0c36;
  }
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Bold.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraLight.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Italic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Light.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Regular.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Black.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-BlackItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito/Nunito-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Black.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
      url('/assets/fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

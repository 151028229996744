@mixin transformBrowserCompatibility($value) {
  transform: ($value);
  -ms-transform: ($value);
  -webkit-transform: ($value);
  -moz-transform: ($value);
  -o-transform: ($value);
}
@mixin transitionBrowserCompatibility($value) {
  transition: ($value);
  -ms-transition: ($value);
  -webkit-transition: ($value);
  -moz-transition: ($value);
  -o-transition: ($value);
}

@mixin borderRadiusBrowserCompatibility($value) {
  border-radius: ($value);
  -ms-border-radius: ($value);
  -webkit-border-radius: ($value);
  -moz-border-radius: ($value);
  -khtml-border-radius: ($value);
  -o-border-radius: ($value);
}

@mixin borderShadowBrowserCompatibility($value) {
  box-shadow: $value;
  -ms-box-shadow: ($value);
  -webkit-box-shadow: ($value);
  -moz-box-shadow: ($value);
  -o-box-shadow: ($value);
}

@mixin userSelectCompatibility() {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.loyalty-screen section.user-option-section-component {
  background: transparent;
  margin-top: -4rem;
  .container {
    background: #fff;
    @include borderRadiusBrowserCompatibility(3rem);
    padding: 2rem 0;
  }
  .option-box {
    display: flex;
    position: relative;
    width: 100%;
    background: #fff;
    border: 4px solid $primary-color;
    @include borderRadiusBrowserCompatibility(2rem);
    color: #000;
    cursor: pointer;
    overflow: hidden;
    &.precission-challange {
      img {
        &.bg {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
        }
        &.robot {
          position: absolute;
          width: 6rem;
          height: auto;
          right: -5%;
          z-index: 1;
          @media(max-width: 678px) {
            width: 8rem;
            top: auto;
            bottom: -4rem;
            right: auto;
            left: 65%;
            @include transformBrowserCompatibility(translateX(-50%));
          }
        }
        &.copy {
          position: absolute;
          width: calc(100% - 1rem);
          height: auto;
          top: 50%;
          left: -10%;
          @include transformBrowserCompatibility(translateY(-50%) scale(0.7));
          z-index: 2;
          @media(max-width: 678px) {
            position: relative;
            width: 100%;
            top: 30%;
            left: 50%;
            @include transformBrowserCompatibility(translate(-50%, -50%) scale(1));
          }
        }
      }
    }
    img {
      width: 50%;
      height: 50%;
    }
    p {
      position: relative;
      width: 50%;
      top: 1.7rem;
    }
    &:hover {
      background: #fae5e9;
      text-decoration: none;
      color: $primary-color;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 678px) {
    margin-top: -1rem;
    .container {
      @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
      padding: 1rem 15px;
    }
    .option-box {
      display: block;
      padding-bottom: 0.5rem;
      border-width: 2px;
      @include borderRadiusBrowserCompatibility(0.7rem);
      img {
        width: 100%;
      }
      p {
        width: 100%;
        top: 0;
      }
    }
  }
}
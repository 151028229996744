@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.service-information-screen section.previous-cycle-component {
	background: $secondary-color;
	@include borderRadiusBrowserCompatibility(1rem);

	.container {
		padding: 2rem 2rem;
		.detail {
			position: relative;
			text-align: center;
			background: #fff;
			@include borderRadiusBrowserCompatibility(5rem);
			padding: 0.5rem 0;
		}
	}

	@media screen and(max-width: 678px) {
		.container {
			padding: 2rem 2rem 3rem;
			.detail {
				font-size: 3.5vw;
			}
		}
	}
}

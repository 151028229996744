@media (max-width: 678px) {
    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.3rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.9rem;
    }

    h1,
    h2,
    h3,
    h4,
    p,
    b,
    a,
    i,
    span,
    b {
        &.text-responsive {
            &.text-xxs {
                font-size: 2.5vw;
            }
            &.text-xs {
                font-size: 12px;
            }
            &.text-sm {
                font-size: 13px;
            }
            &.text-md {
                font-size: 15px;
            }
            &.text-lg {
                font-size: 17px;
            }
            &.text-xlg {
                font-size: 20px;
            }
            &.text-xxlg {
                font-size: 26px;
            }
            &.text-xxxlg {
                font-size: 30px;
            }
        }
    }
}

h1,
h2,
h3,
h4,
p,
b,
a,
i,
span,
b {
    &.line-height-1 {
        line-height: 1;
    }
}

@import "../../../styles/sass-utils/variables";
@import "../../../styles/sass-utils/mixins";
@import "../../../styles/layouts/submain-layout";

.faq-screen {
  padding-top: 4.9rem;
  h1 {
    text-transform: capitalize;
  }
  .content-section {
    background: transparent;
    color: $dark-grey-color;
    margin-top: -4rem;
    .container {
      max-width: 60rem;
      padding: 2rem;
      background: #fff;
      @include borderRadiusBrowserCompatibility(3rem);
    }

    button.tom-button {
      width: 100%;
    }
    .arrow-dropdown {
      position: absolute;
      right: 1rem;
      margin-top: 0.6rem;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      color: $dark-grey-color;
    }
    .information-box {
      color: $dark-grey-color;
      @include transitionBrowserCompatibility(color 0.25s ease-out);
      .title {
        cursor: pointer;
        display: block;
        border-bottom: 1px solid $dark-grey-color;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        p {
          margin-bottom: 0;
        }
      }
      .description {
        overflow: hidden;
        height: 0;
      }
      &.active {
        color: #000;
        .title {
          border-bottom-color: #000;
          .arrow-dropdown {
            @include transformBrowserCompatibility(rotate(-180deg));
          }
        }
        .description {
          height: 100%;
        }
      }
    }
    @media screen and (max-width: 678px) {
      margin-top: -3rem;
      .container {
        @include borderRadiusBrowserCompatibility(1rem 1rem 0 0);
        padding: 2rem 1rem 3rem;
      }
    }
  }
  @media (max-width: 1023px) {
    padding-top: 3rem;
  }
  @include subMainLayout(#fff, "else");
}

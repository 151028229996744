@import "../../../../../styles/sass-utils/variables";
@import "../../../../../styles/sass-utils/mixins";

.service-information-screen section.header-component {
  background: $light-grey-color;
  color: $primary-color;
  text-align: center;
  padding: 4rem 0 7rem;
  line-height: 1;
  margin-bottom: 1rem;
  @media screen and (max-width: 678px) {
    padding: 2rem 0;
  }
}
